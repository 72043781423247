import { StateUpdater, useEffect, useState } from "preact/hooks";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Checkbox, FormControlLabel } from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import moment from "moment-timezone";
import axios from "axios";

import constants from "../constants";
import ButtonConfirm from "./Button/ButtonConfirm";
import disabledTimeZone from '../helpers/disabledTimeZone';
import { Lang, DataForm } from './types/index';
import CustomMessage from "./Home/CustomMessage";
import { addPromoParam } from '../services/baseAPI';
import BookingInformation from "./Cancel/BookingInformation";


const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTypography-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSvgIcon-root": {
      marginRight: "0.8rem",
    },
    "& .booking-date": {
      fontSize: "1rem",
    },
    "& .MuiFormControlLabel-root .MuiSvgIcon-root": {
      marginRight: "0"
    },
    "& form": {
      margin: "20px",
    },
    "& .MuiTypography-body1": {
      fontSize: "1rem",
    }
  },
}));

interface Step3Props {
  lang: Lang;
  buttonTime: string;
  activeStep: number;
  setActiveStep: StateUpdater<number>;
  skipped: string[];
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  guests: string;
  dateBooking: string;
  buttonTimeEnd: string;
  setGlobalBookingNumber?: StateUpdater<string>;
  acceptTerms?: boolean;
  setAcceptTerms?: StateUpdater<boolean>;
  wantNewsRestaurant?: boolean;
  setWantNewsRestaurant?: StateUpdater<boolean>;
  wantNewsMaitres?: boolean;
  setWantNewsMaitres?: StateUpdater<boolean>;
  message?: string;
  ruleId?: string;
  system?: string;
  globalBookingNumber?: string;
  recoupTime?: number;
  selectedParamNoShow: any;
  unitId?: string;
  paramPromo?: string;
  showUserOptions?:boolean;
}

export default function Step3({
  lang,
  buttonTime,
  activeStep,
  setActiveStep,
  skipped,
  firstName,
  lastName,
  phone,
  email,
  guests,
  dateBooking,
  buttonTimeEnd,
  setGlobalBookingNumber=()=>{},
  acceptTerms,
  setAcceptTerms=()=>{},
  wantNewsRestaurant=false,
  setWantNewsRestaurant=()=>{},
  wantNewsMaitres=false,
  setWantNewsMaitres=()=>{},
  message='',
  ruleId='',
  system='',
  recoupTime,
  selectedParamNoShow,
  unitId='',
  paramPromo,
  showUserOptions= true
}: Step3Props) {
  const classes = useStyles();
  const [sendingData, setSendingData] = useState(false);
  const [errorSendData, setErrorSendData] = useState(false);

  const dateTime = moment(dateBooking).tz(constants.TIMEZONE).format("YYYY-MM-DD");
  const timeBookingDuration = moment(disabledTimeZone(buttonTime)).format("H:mm");
  const timeBookingEnd = moment(disabledTimeZone(buttonTimeEnd))
    .subtract(recoupTime, "minutes")
    .format("H:mm");

  const toSendData = skipped.includes(lang.stepsEnum[900]);

  useEffect(() => {
    setWantNewsMaitres(() => true);
    setWantNewsRestaurant(() => true);
    setAcceptTerms(() => true);
  }, []);

  const sendData = () => {
    setSendingData(true);
    const data: DataForm = {
      contact: {
        email,
        fname: firstName,
        lname: lastName,
        mobile: phone,
      },
      guests,
      timeSetDate: moment(dateBooking)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format("YYYY-MM-DDThh:mm:ss"),
      message,
      times: [{ ruleId: +ruleId, start: buttonTime }],
      maitres: {
        caspeco_unit_id: unitId,
        news: wantNewsMaitres,
        restaurant_news: wantNewsRestaurant,
      },
    };

    if (paramPromo) {
      data.maitres.p = paramPromo;
    }

    return axios
      .post(
        addPromoParam(`${constants.APP_API_HOST}/widget/caspeco/WebBookings`),
        data,
        {
          headers: {
            system
          }
        }
      )
      .then((response) => {
        setGlobalBookingNumber(response.data.globalBookingNumber);
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
      })
      .catch((err) => {
        setSendingData(false);
        setErrorSendData(true);
        console.log(err.response);
        console.log(err);
      });
  };

  return (
    <div className={classes.root}>
      <h2 className="step-title">{lang.stepsEnum["350"]}</h2>

      {!toSendData && (
        <div className="box-summary">
          <ConditionHeader>{lang.stepsEnum["300"]}</ConditionHeader>
          {/* {system == 'se_skegam' && unitId == '13' ?
            <p>{lang.bookingMenu.chargeDescriptionSkeppsbron10}</p> :
            <p>{lang.bookingMenu.chargeDescriptionFirstPart} {selectedParamNoShow?.amount_per_person} {lang.bookingMenu.chargeDescriptionSecondPart}</p>
          } */}
          <p>{message}</p>
        </div>
      )}

      <Typography>{lang.stepsEnum["100"]}</Typography>
      <Typography className="booking-date">
        <CalendarTodayIcon fontSize="medium" color="primary" />
        {dateTime} {timeBookingDuration} - {timeBookingEnd}
      </Typography>

      <div className="box-summary">
        <div className="box">
          <h6>{lang.contact.name}</h6>
          <p>
            {firstName} {lastName}
          </p>
        </div>
        <div className="box">
          <h6>{lang.contact.mobile}</h6>
          <p>{phone}</p>
        </div>
        <div className="box">
          <h6>{lang.contact.email}</h6>
          <p>{email}</p>
        </div>
        <div className="box">
          <h6>{lang.guests}</h6>
          <p>{guests}</p>
        </div>
      </div>

      {!toSendData && !(system == 'se_skegam' && unitId == '13') && (
        <div className="box-summary">
          <div className="box">
            <p>
              {selectedParamNoShow?.amount_per_person * Number(guests)} SEK eventuell No
              show-avgift
            </p>
          </div>
        </div>
      )}

      <CustomMessage system={system} unitId={unitId} lang={lang} />

      <ButtonConfirm
        activeStep={activeStep}
        lang={lang}
        acceptTerms={acceptTerms}
        handleNext={
          toSendData
            ? sendData
            : () => setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
        handleBack={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
        sendingData={sendingData}
        errorSendData={errorSendData}
        hasVerticalButtons={!toSendData}
      />

      {showUserOptions && <div className="conditions">
        <p>{lang.contact.acceptTerms1} {!toSendData ? `"${lang.registerCard}"` : `"${lang.next}"`}
          {lang.contact.acceptTerms2} <a href="http://maitres.com/booking-tc" target="_blank">{lang.contact.acceptTerms3}</a>
        </p>
      </div>}
    </div>
  );
}

const ConditionHeader = styled.h1`
  color: #addccf;
  font-size: 20px;
`;
